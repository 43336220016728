import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Layout from '../components/layout'
import SEO from '../components/seo'
import RSVPFindFormByName from '../components/rsvp-find-form-by-name'

const useStyles = makeStyles(() => ({
  textBlock: {
    textAlign: 'center',
    maxWidth: '640px',
    padding: 0,
  },
  forgotInvitationLink: {
    textDecoration: 'underline',
  },
}))

const RSVPName = ({ path, lang }) => {
  const classes = useStyles()

  return (
    <Layout pageName='RSVP' activeRoute={ path }  lang={ lang }>
      <SEO title="RSVP" />
      <Container maxWidth='md'>
        <Box m="48px" />
        <Container className={ classes.textBlock }>
          <RSVPFindFormByName lang={ lang } />
          <Box m="32px" />
        </Container>
      </Container>
    </Layout>
  )
}

export default RSVPName
