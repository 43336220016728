import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios'
import { kellioApiUrl } from '../config/api'
import { navigate } from 'gatsby'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress';
import { translateText } from '../config/translation'

const styles = () => ({
  formElement: {
    display: 'block',
    maxWidth: '450px',
    margin: '48px auto 0 auto',
    '& p': {
      margin: 0,
    }
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 48,
  },
  rsvpButton: {
    marginTop: 32,
    padding: '0 42px',
  },
})

class RSVPFindFormByName extends Component {
  state = {
    loading: true,
    guestNameInput: '',
    invalidGuestName: '',
    loginNames: {},
    rsvpCodes: [],
  }

  componentDidMount() {
    axios.get(`${ kellioApiUrl }/rsvp/login-names`)
    .then(resp => {
      if (resp.status === 200) {
        const rsvpCodes = resp.data.map((currentRsvp) => {
          return currentRsvp.rsvpCode
        })
        const loginNames = resp.data.reduce((result, currentRsvp) => {
          for (let i = 0; i < currentRsvp.loginNames.length; i++) {
            const currentLoginName = currentRsvp.loginNames[i].toLowerCase()
            result[currentLoginName] = currentRsvp.rsvpCode
          }
          return result
        }, {})
        this.setState({
          loginNames: loginNames,
          rsvpCodes: rsvpCodes,
          loading: false,
        })
      }
      else {
        // TODO: HANNDLE ERROR
        this.setState({ loading: false })
      }
    })
    .catch(error => {
      // TODO: HANNDLE ERROR
      this.setState({ loading: false })
    })
  }
  handleGuestNameChange(event) {
    this.setState({ guestNameInput: event.target.value });
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.handleSubmit()
    }
    else if (event.keyCode === 27) {
      this.setState({ guestNameInput: '' });
    }
  }

  handleSubmit() {
    const guestNameInput = this.state.guestNameInput.toLowerCase()
    const rsvpCode = this.state.loginNames[guestNameInput]
    const rsvpLink = this.getRsvpLink(rsvpCode)
    if (rsvpLink) {
      navigate(rsvpLink)
    } else {
      this.setState({ invalidGuestName: this.state.guestNameInput })
      this.setState({ gestNameInput: '' })
    }
  }

  getRsvpLink(rsvpCode) {
    if (this.state.rsvpCodes.indexOf(rsvpCode) > -1) {
      return (this.props.lang === 'pt') ? `/pt/rsvp/${rsvpCode}` : `/rsvp/${rsvpCode}`
    }
    else {
      return false
    }
  }

  render() {
    const classes = this.props.classes
    const lang = this.props.lang

    let rsvpContent = null
    if (this.state.loading) {
      rsvpContent = <Box component='span' className={classes.spinner}><CircularProgress /></Box>
    } else {
      const description = (lang === 'pt') ? <p>Pedimos que confirme sua presença até <strong>1&nbsp;de&nbsp;setembro&nbsp;de&nbsp;2019</strong> para que possamos nos preparar adequadamente.</p> : <p>Please RSVP us no later than <strong>September&nbsp;1,&nbsp;2019</strong> so we can prepare accordingly.</p>
      let instruction = ''
      if (this.state.invalidGuestName.length !== 0) {
        instruction = (lang === 'pt') ? <p>Não encontramos <strong>{this.state.invalidGuestName}</strong> em nossa lista de convidados.<br/ > Por favor tente novamente.</p> : <p>Sorry, but <strong>{this.state.invalidGuestName}</strong> wasn't found on the guest list.<br/ > Please try again.</p>
      } else {
        instruction = (lang === 'pt') ? <p>Se você está respondendo por você + convidado (ou sua família), você poderá confirmar presença para todo o seu grupo.</p> : <p>If you're responding for you and a guest (or your family), you'll be able to RSVP for your entire group.</p>
      }
      rsvpContent = <Box>
        { description }
        <FormControl className={classes.formElement}>
          { instruction }
          <TextField id="name" label={ translateText('Full name', lang) } value={ this.state.guestNameInput } onChange={ this.handleGuestNameChange.bind(this) } onKeyDown={ this.handleKeyDown.bind(this) } required={ true } margin='normal' variant="outlined" fullWidth error={ this.state.invalidGuestName.length !== 0 } />
        </FormControl>
        <Button className={ classes.rsvpButton } onClick={ this.handleSubmit.bind(this) }>
          { (lang === 'pt') ? 'ENVIAR' : 'RSVP' }
        </Button>
      </Box>
    }

    return(rsvpContent)
  }
}

export default withStyles(styles)(RSVPFindFormByName);